import * as React from 'react';
export const ClipboardTagIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.5 5H16c1.1046 0 2 .8954 2 2v11c0 1.1046-.8954 2-2 2H6c-1.1046 0-2-.8954-2-2V7c0-1.1046.8954-2 2-2h2.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 2h-2c-.5523 0-1 .4477-1 1v3c0 .5523.4477 1 1 1h2c.5523 0 1-.4477 1-1V3c0-.5523-.4477-1-1-1ZM8 11h6M8 15h6"
    />
  </svg>
);
