import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import useMedia from 'use-media';
import { zIndex } from '../z-index';

type WidgetMode = 'small' | 'large';

const largeMq = css`
  @media (min-width: 450px) {
    width: 480px;
    height: 600px;
  }

  @media not all and (min-width: 450px) {
    position: fixed;
    width: 100%;
    height: 100%;
  }
`;

const styleBody = /* CSS */ `
html {
  overflow: visible;
}

body {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: fixed;
}
`;

function getIntercomConfig(): {
  intercomEnabled: boolean;
  intercomUrl: string;
} {
  const defConfig = {
    intercomEnabled: false,
    intercomUrl: 'https://dev.sandbox.folio.no/intercom',
  };
  const ele = document.querySelector('[data-intercom-config]');
  if (ele?.textContent) {
    try {
      const config = JSON.parse(ele.textContent);
      return { ...defConfig, ...config };
    } catch (error) {
      Sentry.captureException(error);
    }
  }
  return defConfig;
}

const IFrame = styled.iframe<{ mode: WidgetMode }>`
  border: none;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  ${({ mode }) => (mode === 'large' ? largeMq : null)};
`;

export const Intercom = () => {
  const [widgetMode, setWidgetMode] = React.useState<WidgetMode>('small');
  const isMobile = useMedia('(max-width: 450px)');
  const { current: config } = React.useRef(getIntercomConfig());

  const { current: channelId } = React.useRef(Date.now().toString());
  React.useEffect(() => {
    const onMessage = (evt: MessageEvent) => {
      try {
        const payload = JSON.parse(evt.data);

        if (
          typeof payload === 'object' &&
          payload.channelId &&
          payload.channelId === channelId &&
          payload.kind
        ) {
          if (payload.kind === 'show') {
            setWidgetMode('large');
          } else if (payload.kind === 'hide') {
            setTimeout(() => setWidgetMode('small'), 500);
          }
        }
      } catch {
        // not json, and/or not meant for us
      }
    };

    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [channelId]);

  React.useEffect(() => {
    if (widgetMode === 'large' && isMobile) {
      const scrollTop = (document.scrollingElement || document.documentElement)
        .scrollTop;

      const head = document.head;

      const style = document.createElement('style');
      style.textContent = styleBody;

      head.appendChild(style);

      // meta tag used to prevent zooming on ios
      const meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.content =
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';
      head.appendChild(meta);

      return () => {
        head.removeChild(style);
        head.removeChild(meta);
        if (document.scrollingElement) {
          document.scrollingElement.scrollTop = scrollTop;
        }
      };
    } else {
      return undefined;
    }
  }, [widgetMode, isMobile]);

  if (config.intercomEnabled === false) {
    return null;
  } else {
    const { intercomUrl } = config;
    const url = new URL(intercomUrl);

    url.searchParams.append('channelId', channelId);

    return (
      <div
        css={css`
          position: fixed;
          z-index: ${zIndex.intercom};
          bottom: 0;
          right: 0;
          width: 100px;
          height: 100px;
          ${widgetMode === 'large'
            ? css`
                width: 100vw;
                height: 100vh;
                ${largeMq};
                z-index: ${zIndex.cookieBanner + 1};
              `
            : css`
                overflow: hidden;
                border-radius: 50%;
              `}
        `}
      >
        <IFrame
          mode={widgetMode}
          src={url.toString()}
          title="Chat med kundeservice"
        />
      </div>
    );
  }
};
