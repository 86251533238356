import { css } from '@emotion/react';
import { ProgressSpinner } from 'folio-common-components';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageHeading } from '../../components/page-heading';
import { mountPath } from '../../paths';
import { type RootState, useActions, useSelector } from '../../state';

function selector(e: RootState) {
  return {
    bankSelection: e.founding.bankSelection,
    pristine: e.app.pristine,
    dirty: e.editor.dirty,
    saving: e.app.saving,
  };
}

export const SpvEntryPage: React.FC = () => {
  const { foundingThunks, editorActions } = useActions();
  const { bankSelection, pristine, dirty, saving } = useSelector(selector);
  const { search: locationSearch } = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (saving || dirty) {
      return;
    } else if (pristine) {
      // triggers a save. Must be saved before we can set the bank selection
      editorActions.setDirty(true);
    } else {
      if (bankSelection !== 'OTHER_NAGGED') {
        foundingThunks.setBankSelectionThunk('OTHER_NAGGED');
      } else {
        navigate({
          pathname: mountPath,
          search: locationSearch,
        });
      }
    }
  }, [
    bankSelection,
    dirty,
    editorActions,
    foundingThunks,
    navigate,
    locationSearch,
    pristine,
    saving,
  ]);

  return (
    <div
      css={css`
        text-align: center;
      `}
    >
      <PageHeading>Gjør klar stiftelsen</PageHeading>
      <div
        css={css`
          display: inline-block;
        `}
      >
        <ProgressSpinner size={48} />
      </div>
      <p>Det tar et lite øyeblikk&hellip;</p>
    </div>
  );
};
