import * as React from 'react';
export const HomeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 17v-6.1228a2 2 0 0 1 .5812-1.4095l6-6.0395c.7821-.7873 2.0555-.7873 2.8376 0l6 6.0395c.3723.3746.5812.8814.5812 1.4095V17c0 1.1046-.8954 2-2 2h-3.3333A.6667.6667 0 0 1 13 18.3333V15c0-1.1046-.8954-2-2-2s-2 .8954-2 2v3.3333A.6667.6667 0 0 1 8.3333 19H5c-1.1046 0-2-.8954-2-2Z"
    />
  </svg>
);
