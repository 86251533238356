import * as React from 'react';
export const EnvelopeLetterMailIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <rect
      width={18}
      height={14}
      x={2}
      y={4}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      rx={2.5}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m2.166 8 8.0118 3.6299a2.0002 2.0002 0 0 0 1.6366.0064L20.0092 8"
    />
  </svg>
);
