import { combineReducers } from '@reduxjs/toolkit';
import { reducer as appReducer } from './app';
import { reducer as companySearchReducer } from './companySearch';
import { reducer as editorReducer } from './editor';
import { reducer as foundingReducer } from './founding';
import type { RootAction, RootState } from './types';

export type { RootState, Dispatch } from './types';

export { useSelector, useActions, useStoreState } from './hooks';

export const rootReducer = combineReducers<RootState, RootAction>({
  companySearch: companySearchReducer,
  app: appReducer,
  editor: editorReducer,
  founding: foundingReducer,
});
