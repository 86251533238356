import * as React from 'react';
export const PersonIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    aria-hidden="true"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 10c2.2091 0 4-1.7909 4-4 0-2.2091-1.7909-4-4-4-2.2091 0-4 1.7909-4 4 0 2.2091 1.7909 4 4 4ZM14.08 13l-1.666 1.6228c-.7839.7635-2.0359.7553-2.8097-.0185L8 13h-.08A3.9201 3.9201 0 0 0 4 16.92V18c0 1.1046.8954 2 2 2h10c1.1046 0 2-.8954 2-2v-1.08a3.9204 3.9204 0 0 0-1.1481-2.7719A3.9204 3.9204 0 0 0 14.08 13Z"
    />
  </svg>
);
