import { bindActionCreators } from '@reduxjs/toolkit';
import * as React from 'react';
import {
  useDispatch as reduxUseDispatch,
  // eslint-disable-next-line no-restricted-imports
  useSelector as reduxUseSelector,
  useStore as reduxUseStore,
} from 'react-redux';
import * as app from './app';
import * as companySearch from './companySearch';
import * as editor from './editor';
import * as founding from './founding';
import type { RootState } from './types';

export function useStoreState() {
  return reduxUseStore<RootState>().getState();
}

export function useSelector<T>(selector: (state: RootState) => T) {
  return reduxUseSelector(selector);
}

export function useActions() {
  const dispatch = reduxUseDispatch();
  return React.useMemo(
    () => ({
      appActions: bindActionCreators(app.actions, dispatch),
      appThunks: bindActionCreators(app.thunks, dispatch),
      editorActions: bindActionCreators(editor.actions, dispatch),
      editorThunks: bindActionCreators(editor.thunks, dispatch),
      foundingActions: bindActionCreators(founding.actions, dispatch),
      foundingThunks: bindActionCreators(founding.thunks, dispatch),
      companySearchActions: bindActionCreators(companySearch.actions, dispatch),
      companySearchThunks: bindActionCreators(companySearch.thunks, dispatch),
    }),
    [dispatch],
  );
}
