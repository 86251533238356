import * as React from 'react';
export const CookieCreamIllustration = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={69}
    height={87}
    fill="none"
    viewBox="0 0 69 87"
    aria-hidden="true"
    style={{
      maxWidth: '100%',
      height: 'auto',
    }}
    {...props}
  >
    <path
      fill="#FED491"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M31.6342 81.4785c-2.4762 0-4.6524-1.6509-5.4027-3.9771L13.2498 34.8795h36.844L37.037 77.4264c-.7504 2.4012-2.9265 4.0521-5.4028 4.0521Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M5.2956 34.8795c0-8.1042 6.6034-14.7076 14.7076-14.7076 4.5773 0 8.7045 2.1011 11.4058 5.4028"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M14.3464 78.6017c-1.8009 0-3.3017-1.5007-3.3017-3.3017 0-1.2006.2251-2.3262.6754-3.3767l.8254-2.026c.6753-1.5759 2.9265-1.5759 3.5268 0l.8254 2.026c.4503 1.0505.6754 2.2512.6754 3.3767 0 1.801-1.4257 3.3017-3.2267 3.3017ZM53.3204 68.0466c-1.8009 0-3.3017-1.5008-3.3017-3.3017 0-1.2007.2251-2.3262.6753-3.3768l.8255-2.026c.6753-1.5758 2.9265-1.5758 3.5268 0l.8254 2.026c.4502 1.0506.6754 2.2512.6754 3.3768.075 1.8009-1.4258 3.3017-3.2267 3.3017Z"
    />
    <path
      stroke="#FFB938"
      strokeLinecap="round"
      strokeWidth={2.877}
      d="M39.0129 49.5872 23.8835 60.6044M39.2266 60.8141l-13.403 10.3565M24.4055 49.5871l15.1293 11.0172M24.1916 60.8141l13.403 10.3565"
    />
    <path
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="m20.0443 56.5215 6.1872 20.622c.7503 2.3262 2.9265 3.977 5.4027 3.977 2.4763 0 4.6524-1.6508 5.4028-4.0521l8.7605-28.5469"
    />
    <path
      fill="#fff"
      stroke="#1D1D1D"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M45.8654 20.6778c-1.2007-6.9036-7.2037-12.1563-14.4825-12.1563-7.2788 0-13.2819 5.2527-14.4074 12.1563-6.8286 1.2756-11.9312 7.2787-11.9312 14.4074 0 8.1042 6.6034 14.7076 14.7076 14.7076 4.8025 0 9.0046-2.2512 11.706-5.778 2.7014 3.5268 6.9036 5.778 11.7061 5.778 8.1042 0 14.7076-6.6034 14.7076-14.7076-.1501-7.1287-3.8279-13.281-12.0062-14.4074Z"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M43.0889 49.7928c-6.4351 0-11.9239-4.1635-13.9119-9.9359"
    />
    <path
      fill="#fff"
      d="M20.8774 47.373v8.0256c0 2.3114-2.1011 4.2266-4.8025 4.2266-2.6264 0-4.8025-1.8492-4.8025-4.2266V39.5178"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M20.8774 47.373v8.0256c0 2.3114-2.1011 4.2266-4.8025 4.2266-2.6264 0-4.8025-1.8492-4.8025-4.2266V39.5178"
    />
    <path
      fill="#fff"
      d="M57.8716 35.0852v10.7305c0 2.6264-2.1011 4.8025-4.8025 4.8025-2.6264 0-4.8025-2.1011-4.8025-4.8025v-7.3538"
    />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3.002}
      d="M57.8716 35.0852v10.7305c0 2.6264-2.1011 4.8025-4.8025 4.8025-2.6264 0-4.8025-2.1011-4.8025-4.8025v-7.3538"
    />
    <mask id="CookieCreamIllustration__a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M56.679 33.302c4.8786-2.1881 8.2767-7.0874 8.2767-12.7805 0-7.732-6.268-14-14-14s-14 6.268-14 14c0 1.1881.148 2.3417.4266 3.4433a13.5004 13.5004 0 0 1 9.0589-1.1091A13.5001 13.5001 0 0 1 56.679 33.302Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#8B651E"
      fillRule="evenodd"
      d="M56.679 33.302c4.8786-2.1881 8.2767-7.0874 8.2767-12.7805 0-7.732-6.268-14-14-14s-14 6.268-14 14c0 1.1881.148 2.3417.4266 3.4433a13.5004 13.5004 0 0 1 9.0589-1.1091A13.5001 13.5001 0 0 1 56.679 33.302Z"
      clipRule="evenodd"
    />
    <path
      fill="#1D1D1D"
      d="m56.679 33.302-2.9385.6044.7534 3.6635 3.4127-1.5306-1.2276-2.7373Zm-19.2967-9.3372-2.9084.7355.9171 3.6267 3.3409-1.6829-1.3496-2.6793Zm.1903-.094-1.3073-2.7002 1.3073 2.7002Zm8.8686-1.0151.6635-2.9257-.6635 2.9257Zm7.5633 4.7413-2.3442 1.8721 2.3442-1.8721Zm7.9512-7.0755c0 4.4685-2.6647 8.3211-6.5044 10.0432l2.4553 5.4746c5.9175-2.654 10.0491-8.6002 10.0491-15.5178h-6Zm-11-11c6.0751 0 11 4.9249 11 11h6c0-9.3889-7.6112-17-17-17v6Zm-11 11c0-6.0751 4.9249-11 11-11v-6c-9.3889 0-17 7.6111-17 17h6Zm.335 2.7078a11.0274 11.0274 0 0 1-.335-2.7078h-6c0 1.4382.1793 2.8388.5182 4.1788l5.8168-1.471Zm-4.0254-2.0587a18.607 18.607 0 0 0-.2327.1149l2.6993 5.3586c.0492-.0248.0985-.0492.1481-.0732l-2.6147-5.4003ZM47.1047 19.93a16.5006 16.5006 0 0 0-10.8394 1.2406l2.6147 5.4003a10.5 10.5 0 0 1 6.8978-.7894l1.3269-5.8515Zm9.244 5.7948a16.5007 16.5007 0 0 0-9.244-5.7948l-1.3269 5.8515a10.5 10.5 0 0 1 5.8825 3.6876l4.6884-3.7443Zm3.2687 6.9728a16.5003 16.5003 0 0 0-3.2687-6.9728l-4.6884 3.7443a10.5 10.5 0 0 1 2.0802 4.4373l5.8769-1.2088Z"
      mask="url(#CookieCreamIllustration__a)"
    />
    <circle cx={50.456} cy={15.021} r={1.5} fill="#1D1D1D" />
    <circle cx={58.456} cy={17.021} r={1.5} fill="#1D1D1D" />
    <circle cx={52.456} cy={21.021} r={1.5} fill="#1D1D1D" />
    <circle cx={44.456} cy={20.021} r={1.5} fill="#1D1D1D" />
    <circle cx={57.456} cy={25.021} r={1.5} fill="#1D1D1D" />
    <circle cx={44.456} cy={13.021} r={1.5} fill="#1D1D1D" />
    <circle cx={53.456} cy={10.021} r={1.5} fill="#1D1D1D" />
    <path
      stroke="#1D1D1D"
      strokeLinecap="round"
      strokeWidth={3}
      d="M47.9909 21.7494a13.5007 13.5007 0 0 0-14.4855 4.11"
    />
  </svg>
);
