import { flatten, invariant } from 'folio-common-utils';
import type { RootState } from '../';
import type { SigningAction } from '../../gqltypes';
import { allSigned } from '../../utils';
import type {
  BaseFoundingEvent,
  FoundingDoneEvent,
  FoundingEvent,
  FoundingReducerState,
  SigningEvent,
} from './types';

export function hasSingleSigner(state: RootState) {
  return signings(state).length === 1;
}

function takeAfter(events: readonly BaseFoundingEvent[], resetAfter: string) {
  return events.reduce<readonly BaseFoundingEvent[]>((prev, cur) => {
    return cur.__typename === resetAfter ? [] : prev.concat(cur);
  }, []);
}

function validSigningEvents(events: readonly BaseFoundingEvent[]) {
  return takeAfter(events, 'ReopenedEvent').filter(isSigningEvent);
}

function isSigningEvent(event: FoundingEvent): event is SigningEvent {
  return event?.__typename === 'SigningEvent';
}

export function getSuccessfulSignatures(state: RootState): readonly string[] {
  return validSigningEvents(state.founding.events)
    .filter(e => e.action === 'SIGNED')
    .map(e => e.user)
    .sort();
}

function getLastEventType(state: RootState) {
  if (state.founding.events.length === 0) {
    return undefined;
  }
  return state.founding.events[state.founding.events.length - 1].__typename;
}

export type Progress =
  | 'open'
  | 'submitted'
  | 'await_signing'
  | 'await_open_account'
  | 'await_all_signing'
  | 'await_payment_documentation'
  | 'await_brreg'
  | 'finished';

/**
 * Check if this founding wants to use a different bank than Folio for
 * aksjekapital.
 */
export function isOptedOutOfFolioAsBank(
  bankSelection: FoundingReducerState['bankSelection'],
): bankSelection is 'OTHER' | 'OTHER_NAGGED' | 'FOLIO_REJECTED' {
  return (
    bankSelection === 'OTHER' ||
    bankSelection === 'OTHER_NAGGED' ||
    bankSelection === 'FOLIO_REJECTED'
  );
}

export function foundingProgress(state: RootState): Progress {
  // FIXME: get rid of this. It's currently needed to get a spinner when re-opening.
  if (state.founding.signingStatus === 'OPEN') {
    return 'open';
  }

  if (isOptedOutOfFolioAsBank(state.founding.bankSelection)) {
    const foundingSigningIsComplete = allSigned(state.founding.signerURLs);

    switch (state.founding.bankSelection) {
      case 'FOLIO_REJECTED':
        // User will not be using Folio, because they were rejected
        return 'await_open_account';

      case 'OTHER':
        // No bank has been selected, and we have not nagged yet
        return 'await_open_account';

      case 'OTHER_NAGGED': {
        // User has confirmed that they want to use a different bank

        if (foundingSigningIsComplete) {
          // Everyone has signed, and they've selected another bank, so
          // as far as Folio is concerned, the founding is complete.
          return 'finished';
        } else {
          // There are signatures remaining
          return 'await_all_signing';
        }
      }
      default:
        throw new Error('Illegal state');
    }
  } else {
    switch (getLastEventType(state)) {
      case 'SubmittedEvent':
        return 'submitted';

      case 'ConfirmedEvent':
        return 'await_signing';

      case 'SigningEvent':
      case 'SigningDoneEvent': {
        const successfulSignatures = getSuccessfulSignatures(state);
        const formOwnerHasSigned = successfulSignatures.includes(
          state.editor.formOwner,
        );

        if (!formOwnerHasSigned) {
          return 'await_signing';
        }

        const { virksomhetsInfoSignerURLs, bankSelection } = state.founding;

        // When everyone has signed virksomhetsinfo, the
        // the share capital must be paid
        if (allSigned(virksomhetsInfoSignerURLs)) {
          return 'await_payment_documentation';
        }

        // AML has been completed, wait for everyone to sign
        if (bankSelection === 'FOLIO_AML_COMPLETE') {
          return 'await_all_signing';
        }

        // AML has not been completed
        return 'await_open_account';
      }

      case 'PaymentReceiptReceivedEvent':
      case 'RegisteringFoundingEvent':
      case 'ProcessingFoundingEvent':
        return 'await_brreg';

      case 'FoundingDoneEvent':
        return 'finished';

      // 'FoundingStartedEvent'
      // 'ReopenedEvent'
      default:
        return 'open';
    }
  }
}

export function foundingProgressSteps(state: RootState): {
  progress: Progress;
  label: React.ReactNode;
}[] {
  if (isOptedOutOfFolioAsBank(state.founding.bankSelection)) {
    // Don't show any progress for non-folio flow.
    return [];
  }

  return [
    {
      label: 'Lage dokumenter',
      progress: 'await_signing',
    },
    {
      label: 'Søke om konto',
      progress: 'await_open_account',
    },
    {
      label: 'Signere søknad',
      progress: 'await_all_signing',
    },
    {
      label: 'Overføre aksjekapital',
      progress: 'await_payment_documentation',
    },
    {
      label: 'Vente på registrering',
      progress: 'await_brreg',
    },
    {
      label: 'Ditt AS er registrert!',
      progress: 'finished',
    },
  ];
}

type SigningState = SigningAction | 'PENDING';

export function signings(state: RootState) {
  const statusMap: { [id: string]: SigningState } = {};

  if (!state.founding.foundingDetails) {
    return [];
  }

  const info = state.founding.foundingDetails;

  const peopleIds: readonly string[] = Array.from(
    new Set([
      state.founding.foundingDetails.formOwner,
      ...flatten<string>(info.owners.map(e => e.signatories)),
    ]),
  );

  peopleIds.forEach(e => {
    statusMap[e] = 'PENDING';
  });

  validSigningEvents(state.founding.events).forEach(e => {
    statusMap[e.user] = e.action;
  });

  return Object.entries(statusMap).map(([key, status]) => {
    const urlItem = state.founding.signerURLs.find(e => e.id === key);
    const person = info.people.find(e => e.id === key);
    invariant(person);
    return {
      id: key,
      name: person.name,
      roles: info.roles.filter(e => e.person === key).map(e => e.role),
      status,
      url: urlItem ? urlItem.url : undefined,
    };
  });
}

export function getOrgId(state: RootState) {
  const evt = state.founding.events.find(
    e => e.__typename === 'FoundingDoneEvent',
  );
  if (evt) {
    return (evt as FoundingDoneEvent).orgId;
  } else {
    return undefined;
  }
}
