import { createNextState } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { invariant, removeNonDigits, waitReject } from 'folio-common-utils';
import type {
  Role as BoardMemberRole,
  ConfirmSessionMutationVariables,
  MaBankSelection,
} from '../../gqltypes';
import * as services from '../../services';
import { settings } from '../../settings';
import { actions as appActions } from '../app';
import {
  type EditorReducerState,
  type Role,
  selectors as editorSelectors,
} from '../editor';
import { actions as foundingActions, selectors } from '../founding';
import type { Dispatch, GetState } from '../types';

function roleToGqlRole(role: Role): BoardMemberRole {
  switch (role) {
    case 'boardMember':
      return 'BOARDMEMBER';
    case 'chair':
      return 'CHAIR';
    case 'deputy':
      return 'DEPUTY';
  }
}

function getIdsOfPeopleToInclude(editor: EditorReducerState) {
  const peopletoInclude: (string | undefined)[] = [
    ...editor.roles.map(e => e.id),
    ...Object.keys(editor.equity),
    editor.formOwner,
    editor.ceo,
  ];

  Object.values(editor.companies).forEach(e => {
    if (editor.equity[e.id] === undefined) {
      return;
    }

    for (const s of e.signatories) {
      peopletoInclude.push(s);
    }
  });

  if (editor.coreInfo.orgTransfer) {
    peopletoInclude.push(editor.coreInfo.orgTransfer.signatory);
  }

  return peopletoInclude;
}

// If the user has added companies, and then selects single ownership, we
// still keep the companies in the state.
// When confirming, we want to remove them.
function fixupEditorState(editor: EditorReducerState) {
  if (editor.ownershipType === 'single') {
    return {
      ...editor,
      companies: {},
    };
  }

  return editor;
}

export function serializeEditorToFounding(
  editor: EditorReducerState,
): ConfirmSessionMutationVariables {
  editor = fixupEditorState(editor);
  const peopletoInclude = getIdsOfPeopleToInclude(editor);
  const equityMap = editorSelectors.equityByOwners(editor);
  const roles = editor.roles.map(e => ({
    person: e.id,
    role: roleToGqlRole(e.role),
  }));

  if (editor.ceo !== undefined) {
    roles.push({ person: editor.ceo, role: 'CEO' });
  }

  const f: ConfirmSessionMutationVariables['founding'] = {
    id: editor.id,
    address: editor.coreInfo.address,
    capital: Number(editor.coreInfo.capital) || 0,
    formOwner: editor.formOwner,
    foundingDate: editor.coreInfo.foundingDate,
    name: editor.coreInfo.name,
    postalCode: editor.coreInfo.postalCode,
    purpose: editor.coreInfo.purpose || '',
    verbosePurpose: editor.coreInfo.verbosePurpose || '',
    activityId: editor.coreInfo.activityId,
    activityCategoryId: editor.coreInfo.activityCategoryId,
    shareCount: Number(editor.coreInfo.shareCount) || 0,
    willHaveEmployees: editor.coreInfo.willHaveEmployees,
    companyType: editor.coreInfo.companyType,
    orgTransfer: editor.coreInfo.orgTransfer,

    people: Object.values(editor.people)
      .filter(e => peopletoInclude.includes(e.id))
      .map(e => ({
        id: e.id,
        name: e.name,
        address: e.address || '',
        postalCode: e.postalCode || '',
        pNum: removeNonDigits(e.pNum),
        email: e.email || '',
        mobile: removeNonDigits(e.phone) || '',
      })),

    companies: Object.values(editor.companies).map(e => ({
      id: e.id,
      orgId: e.orgId,
    })),

    roles,

    owners: Object.entries(equityMap).map(([id, equity]) => {
      let signatories: string[] = [];
      if (editor.people[id]) {
        signatories.push(id);
      } else {
        signatories = editor.companies[id].signatories;
      }
      return { id, shares: Number(equity) || 0, signatories };
    }),
  };
  return { founding: f };
}

export function mergeFormOwnerDuplicate(
  state: EditorReducerState,
): EditorReducerState {
  const formOwnerId = state.formOwner;
  const formOwner = Object.values(state.people).find(
    person => person.id === formOwnerId,
  );
  invariant(formOwner, 'No form owner');
  const duplicatePerson = Object.values(state.people).find(
    person => person.pNum === formOwner.pNum && person.id !== formOwnerId,
  );

  if (!duplicatePerson) {
    return state;
  }

  return createNextState(state, draft => {
    const duplicatePersonId = duplicatePerson.id;
    // Overwrite the duplicate person with the form owner in ...
    // Equity
    const equity = draft.equity[duplicatePersonId];
    if (equity) {
      if (draft.equity[formOwnerId]) {
        throw new Error('Error merging: form owner also has equity');
      }
      delete draft.equity[duplicatePersonId];
      draft.equity[formOwnerId] = equity;
    }

    // Company signatories
    Object.values(draft.companies).forEach(company => {
      // There's only one signatory
      if (company.signatories[0] === duplicatePersonId) {
        company.signatories[0] = formOwnerId;
      }
    });

    // Board members
    const formOwnerRole = draft.roles.find(role => role.id === formOwnerId);
    const duplicateRole = draft.roles.find(
      role => role.id === duplicatePersonId,
    );
    if (duplicateRole) {
      if (!formOwnerRole) {
        // If the duplicate has a role, but the form owner doesn't, replace the id with the form owner's
        duplicateRole.id = formOwnerId;
      } else if (formOwnerRole.role !== duplicateRole.role) {
        throw new Error(
          'Error merging: the roles of the form owner and the duplicate do not match',
        );
      }
    }

    // CEO
    if (draft.ceo === duplicatePersonId) {
      draft.ceo = formOwnerId;
    }

    // Remove the duplicate person
    delete draft.people[duplicatePersonId];

    return draft;
  });
}

export function confirmThunk(): any {
  return async function (dispatch: Dispatch, getState: GetState) {
    dispatch(appActions.setSavingActive());
    const state = getState();
    const editorState = mergeFormOwnerDuplicate(state.editor);
    const foundingInput = serializeEditorToFounding(editorState);
    const timeoutSym = {};

    try {
      const race = Promise.race([
        services.confirmSession(foundingInput),
        waitReject(timeoutSym, settings.networkTimeout),
      ]);
      const response = await race;
      if (response !== null) {
        dispatch(foundingActions.updateFounding(response));
      }
    } catch (error) {
      dispatch(
        appActions.addMessage({
          body: 'Noe gikk galt! Ta kontakt med oss i chatten for å komme videre.',
        }),
      );
      if (error === timeoutSym) {
        Sentry.addBreadcrumb({ message: 'Request timed out' });
        Sentry.captureException(error);
      } else {
        Sentry.captureException(error);
      }
    }
    dispatch(appActions.setSavingIdle());
  };
}

export function updateProgressThunk(): any {
  return async function (dispatch: Dispatch, getState: GetState) {
    const state = getState();
    const progress = selectors.foundingProgress(state);
    const timeSinceLastPoll = Date.now() - state.app.lastStatusPollTime;

    // Poll every 5 seconds. After 5 hours, poll less frequently.
    const pollTimeout =
      Date.now() - state.app.bootedTime > 1000 * 60 * 60 * 5
        ? settings.statusPollTimeout
        : 5000;

    if (!state.app.loading) {
      if (progress === 'submitted' || timeSinceLastPoll > pollTimeout) {
        dispatch(appActions.setLoadingActive());
        dispatch(appActions.touchPollTime());
        const response = await services.pollFoundingState({
          id: state.editor.id,
        });
        if (response) {
          dispatch(
            appActions.setBrregProcessingTime(response.brregProcessingTime),
          );
          if (response.founding) {
            dispatch(foundingActions.updateFounding(response.founding));
          }
        }
        dispatch(appActions.setLoadingIdle());
      }
    }
  };
}

export function reopenThunk(): any {
  return async function (dispatch: Dispatch, getState: GetState) {
    const state = getState();
    try {
      await services.reopen({ id: state.editor.id });
      dispatch(
        foundingActions.updateFounding({
          signingStatus: 'OPEN',
        }),
      );
    } catch {
      dispatch(
        appActions.addMessage({
          body: 'Noe gikk skeis. Du kan trygt prøve på nytt.',
        }),
      );
    }
  };
}

export function setBankSelectionThunk(bankSelection: MaBankSelection) {
  return async function (dispatch: Dispatch, getState: GetState) {
    const state = getState();
    try {
      const result = await services.setBankSelection({
        id: state.editor.id,
        selection: bankSelection,
      });
      dispatch(foundingActions.updateFounding({ bankSelection: result }));
    } catch {
      dispatch(
        appActions.addMessage({
          body: 'Noe gikk skeis. Du kan trygt prøve på nytt.',
        }),
      );
    }
  };
}
